import Image from 'next/image';
import Link from 'next/link';

const loadBackground = (id) => {
  switch (id) {
    case 3:
      return '/images/contribute-bg.png'
      break;
    default:
      return '/images/contribute-bg.png';
  }
};

const TitleTextButtonCenter = ({contentObj}) => {
  const content = contentObj.content;
  const contentID = contentObj.id;

  return (
    <section className="text-white body-font bg-cover bg-center bg-no-repeat w-full orange-gradient-border-top" style={{backgroundImage: `url(${loadBackground(contentID)})`}}>
      <div className="container mx-auto flex px-5 py-40 items-center justify-center flex-col">
        <div className="text-center lg:w-2/3 w-full">
          {content[0].Headline && <h2 className="lato-title lato-h2 text-3xl mb-4 font-medium text-white">{content[0].Headline}</h2>}
          {content[0].Subtext && <p className="mb-8 leading-relaxed max-w-md m-auto">{content[0].Subtext}</p>}
          {content[0].Button?.Text &&
            <div className="flex justify-center">
              <Link href={content[0].Button?.URL}>
                <a className="flex content-between text-white btn-orange border-0 btn-padding focus:outline-none text-lg w-svg">{content[0].Button.Text}
                  <svg width="25.032" height="21.342" viewBox="0 0 25.032 21.342">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_29" data-name="Rectangle 29" width="21.342" height="25.032" fill="#fff"/>
                      </clipPath>
                    </defs>
                    <g id="Group_49" data-name="Group 49" transform="translate(25.032) rotate(90)">
                      <path id="Path_85" data-name="Path 85" d="M10.661,0,0,10.622l.824.827,9.254-9.221L10.1,22.51h1.167L11.246,2.234l9.271,9.259.825-.826Z" transform="translate(0)" fill="#fff"/>
                      <g id="Group_34" data-name="Group 34" transform="translate(0 0)">
                        <g id="Group_33" data-name="Group 33" clipPath="url(#clip-path)">
                          <path id="Path_86" data-name="Path 86" d="M42.483,100.261a.841.841,0,1,0,1.189,0,.84.84,0,0,0-1.189,0" transform="translate(-32.376 -76.664)" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </Link>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default TitleTextButtonCenter;