import React, { useState } from "react";
import { PopupboxManager } from 'react-popupbox';

const Video = () => {
  return (
    <div className="container">
      <video autoPlay playsInline src={video} />
    </div>
  );
};

const VideoHero = ({contentObj}) => {
  const content = contentObj.content;
  const contentID = contentObj.id;
  const videoSrc = content[0].BackgroundVideo.data?.attributes.url;
  const imageSrc = content[0].BackgroundImage.data?.attributes.url;

  const openPopupbox = () => {
    const content = (
      <div className="container">
        <video controls controlsList="nodownload" autoPlay playsInline src={videoSrc} />
      </div>
    )
    PopupboxManager.open({ 
      content,
      config: {
        className: "video-popup",
        overlayOpacity: 0.25, 
      }
    })
  }

  return (
    <section className="text-white body-font bg-cover bg-center bg-no-repeat w-full" style={{backgroundImage: `url(${imageSrc})`}}>
      <div className="container mx-auto flex px-5 py-60 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-left">
          {content[0].Headline && <h2 className="lato-title lato-h2 text-3xl mb-4 font-medium text-white">{content[0].Headline}</h2>}
          {content[0].Subtext && <p className="mb-8 leading-relaxed max-w-md">{content[0].Subtext}</p>}
          <div onClick={openPopupbox} className="flex justify-center video-btn">
            <div className="flex justify-center">
                <a className="flex w-full content-between text-white btn-orange border-0 btn-padding focus:outline-none text-lg w-svg cursor-pointer">Play Video 
                  <svg id="Component_27_1" className="play-video-btn" width="58.653" height="58.653" viewBox="0 0 58.653 58.653">
                    <defs></defs>
                    <path id="Path_89" data-name="Path 89" d="M45.037,58.818l21.7-16.1-21.7-16.1Zm2.484-27.26L62.563,42.72,47.521,53.881Z" transform="translate(-22.659 -13.393)" fill="#fff"/>
                    <g id="Group_51" data-name="Group 51">
                      <g id="Group_50" data-name="Group 50">
                        <path id="Path_90" data-name="Path 90" d="M58.653,29.326A29.326,29.326,0,1,0,29.326,58.653a29.043,29.043,0,0,0,17.938-6.134l-1.521-1.964a26.581,26.581,0,0,1-16.417,5.613A26.842,26.842,0,1,1,56.168,29.326a26.589,26.589,0,0,1-5.53,16.311l1.972,1.512a29.054,29.054,0,0,0,6.043-17.823" transform="translate(0 0)" fill="#fff"/>
                        <path id="Path_91" data-name="Path 91" d="M95.883,95.567a1.789,1.789,0,1,0,2.53,0,1.789,1.789,0,0,0-2.53,0" transform="translate(-47.976 -47.817)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>
                </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoHero;